import React from 'react';
import Wrapper from '../components/Wrapper';
import PageTitle from '../modules/PageTitle';
import {Col, Row } from "antd";
import water from '../images/be-water.gif';

export default class IndexPage extends React.Component {
    render(){
        return (
            <Wrapper>
                <PageTitle title={"418 - I'm a teapot"} />
                <Row>
                    <Col xs={{span: 24}} style={{padding: "16px"}}>
                        <p>&nbsp;</p>
                        <h2>Let's keep this short ... (and stout)</h2>
                        <p>To be honest, we haven't let the content writers or marketing team know about this page, it's our developer secret... we didn't even tell the designers so we can use a poor quality GIF...</p>
                        <p>We like to have fun at Brew, whether that is in the form of an easter egg, small app/game on someones birthday/leaving do, hating on social media data collection, or just generally mocking the state of most websites with all their pop-up confirmations and alerts.</p>
                        <h2>Stay tuned</h2>
                        <p>We have some big plans for this page in future, if we ever get time to finish it. For now, here is a Bruce Lee GIF, be water my friend!</p>
                        <p className="text-center">
                            <img src={water} alt="be water my friend!" />
                        </p>
                        <h2>Most ridiculous things we have been asked to do</h2>
                        <p>This is just a random list in no particular order of some of the crazy things we have been asked:</p>
                        <ul>
                            <li>"Can you delete this persons post on social media where they have said I gave them bad service"</li>
                            <li>"Needs more WOW FACTOR" ...added .wow-factor class with font-size increase... "Perfect, thanks!"</li>
                            <li>"I know we don't have permission to use the data but can we send the email anyway?"</li>
                            <li>"Can we just grab an image from Google for this post?"</li>
                            <li>"There is something wrong with my website, it has been translated to Spanish or something?" ... We used Lorem Ipsum placeholder text.</li>
                            <li>Showing wireframes to client "This looks great, just one comment... aren't we using our colour scheme?"</li>
                        </ul>
                    </Col>
                </Row>
            </Wrapper>
        );
    }
}
